$.fn.isOnScreen = function (offset, mode){
	offset = offset || 0;
	mode = mode || 'visible';

	var rect = $(this)[0].getBoundingClientRect(),
		viewHeight = Math.max($(this).height(), $(window).innerHeight()),
		above = rect.bottom - offset < 0,
		below = rect.top - viewHeight + offset >= 0;

	return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
};

var ariaBool = function (toggle){
	if(toggle === undefined){
		toggle = 'false';
	}

	else if(toggle === true){
		toggle = 'true';
	}

	else if(toggle === false){
		toggle = 'false';
	}

	else if(!(toggle == 'true' || toggle == 'false')){
		toggle = 'false';
	}

	return toggle;
};

$.fn.ariaHidden = function (toggle){
	$(this).attr('aria-hidden', ariaBool(toggle));
	return $(this);
};

$.fn.ariaExpanded = function (toggle){
	$(this).attr('aria-expanded', ariaBool(toggle));
	return $(this);
};

$.fn.ariaHasPopup = function (toggle){
	$(this).attr('aria-haspopup', ariaBool(toggle));
	return $(this);
};

$.fn.ariaLabel = function (label){
	$(this).attr('aria-label', label);
	return $(this);
};

$.fn.ariaControls = function (label){
	$(this).attr('aria-label', label);
	return $(this);
};

var emBase = 16;
var pxToEm = function ($length){
	return ($length / emBase).toString() + 'em';
};

var remBase = 16;
var pxToRem = function ($length){
	return ($length / remBase).toString() + 'rem';
};

$(document).foundation();

$(document).ready(function (e){

	$('.js-hide').hide();

	$('.js-show').show();

	// $('.lightbox').magnificPopup({type: 'image'});

	// $('.lightbox-gallery').magnificPopup({type: 'image', gallery: {enabled: true}});

	// $('.lightbox-video').magnificPopup({type: 'iframe'});

	$('input.trigger-submit, select.trigger-submit').change(function (e){
		$(this).parents('form').submit();
	});

	// # Anchor tag fix for <base href>
	$("a[href^='\#'][href!='\#']:not(.bound)").each(function (i, e){
		var href = $(e).attr("href");
		if($("a[name='" + href.substring(1) + "']").length){
			$(e).attr("href", $(location).attr("href").split("#", 1)[0] + href);
		}
	});

	var html = $('html');
	var body = $('body');
	var header = $("#header");
	var nav = $('#main-nav');
	var searchOverlaySelector = "site-search-field";
	var searchOverlay = $("#" + searchOverlaySelector);
	var searchButton = $("#search-submit");
	var searchField = $("#search-field");
	var content = $("#content");
	var main = $("#main");
	var footer = $('#footer');
	var dropdownParent = $('.has-submenu');
	var dropdown = $('.submenu');
	var navToggle = $("#nav-toggle");
	var modalToggle = $('a.modal-toggle');
	var searchHasInputClass = 'has-input';
	var searchActiveClass = 'active-search';
	var activeNavClass = "active-nav";
	var timeOut = 400;
	var ajaxLoader = $('#ajax-read-more');
	var postsContainerId = '#blog-post-list';
	var postsContainer = $(postsContainerId);
	var translate = $("#google_translate_element");

	var headerOffset = function (){
		if(body.hasClass('sticky-header')){
			var headHeight = pxToRem(header.outerHeight());

			content.css('paddingTop', headHeight);
			nav.css('top', headHeight);
			$('.anchor').css('top', "-" + headHeight);
		}

		var transWidth = translate.width();
		var winWidth = $(window).width();
		var headMaxWidth = header
			.children('.row')
			.css('maxWidth')
			.replace(/[^-\d\.]/g, '');
		var margin = winWidth > headMaxWidth ? (winWidth - headMaxWidth) / 2 : 0;

		if(margin > transWidth){
			translate.css({
				right: 0,
				left: 'auto'
			});
		}

		else{
			var position = header
				.find('.phone')
				.position()
				.left;

			translate.css({
				right: 'auto',
				left: pxToRem(position - transWidth - 16)
			});
		}
	};

	var footerOffset = function (){
		if(body.hasClass('sticky-footer')){
			var footHeight = footer.prop('scrollHeight');

			content.css('paddingBottom', pxToRem(footHeight));
			footer.css('marginTop', pxToRem(0 - footHeight));
		}
	};

	var isUsingMobileNav = function (){
		return navToggle.css('display') != 'none';
	};

	var dropdownOffset = function (){
		dropdownParent.each(function (){
			var anchorHeight = $(this).children('a').first().prop('scrollHeight');
			var menu = $(this).children('.submenu');

			menu.css('top', pxToRem(anchorHeight));
		});
	};

	var bodyAriaToggle = function (toggle){
		content.ariaHidden(toggle);
		footer.ariaHidden(toggle);
		navToggle.ariaHidden(toggle);
	};

	var modalToggleAria = function (toggle){
		modalToggle.each(function (){
			$(this).ariaExpanded(toggle);
		});
	};

	$.fn.submenuToggle = function (timeOut){
		var shouldFade = !isUsingMobileNav();

		if(shouldFade){
			$(this).fadeToggle(timeOut);
		}

		else{
			$(this).slideToggle(timeOut);
		}

		return $(this);
	};

	$.fn.submenuShow = function (timeOut){
		var shouldFade = !isUsingMobileNav();

		if(shouldFade){
			$(this).fadeIn(timeOut);
		}

		else{
			$(this).slideDown(timeOut);
		}

		return $(this);
	};

	$.fn.submenuHide = function (timeOut){
		var shouldFade = !isUsingMobileNav();

		if(shouldFade){
			$(this).fadeOut(timeOut);
		}

		else{
			$(this).slideUp(timeOut);
		}

		return $(this);
	};

	$.fn.closeDropdownMenu = function (timeOut){
		$(this).removeClass(activeNavClass);

		$(this)
			.children(".submenu")
			.each(function (){
				$(this)
					.ariaHidden(true)
					.ariaExpanded(false)
					.submenuHide(timeOut);
			});

		return $(this);
	};

	$(window).on('resize load', function (){
		var isMobile = isUsingMobileNav();
		headerOffset();
		footerOffset();
		dropdownOffset();

		if(isMobile && !nav.hasClass(activeNavClass)){
			nav
				.ariaExpanded(false)
				.ariaHidden(true);

			dropdown.hide();
		}

		else{
			nav
				.ariaExpanded(true)
				.ariaHidden(false);
		}

		if(isMobile){
			navToggle.ariaHidden(false);
		}

		else{
			navToggle.ariaHidden(true);
		}
	});

	function responsiveTranslateDropdown(){
		var menu = $('.goog-te-menu-frame')
			.contents()
			.find('.goog-te-menu2');

		if(menu.length){
			menu.css({
				maxWidth: '100%',
				overflow: 'auto',
				boxSizing: 'border-box',
				height: 'auto'
			});
		}
		else{
			setTimeout(responsiveTranslateDropdown, 50);
		}
	}

	responsiveTranslateDropdown();

	searchButton.click(function (e){
		if(!$(this).hasClass(searchHasInputClass)){
			e.preventDefault();

			if($(this).hasClass(searchActiveClass)){
				$(this)
					.removeClass(searchActiveClass)
					.ariaLabel('Open Search Window');

				searchOverlay
					.removeClass(searchActiveClass)
					.ariaHidden(true)
					.ariaExpanded(false)
					.fadeOut(timeOut);
			}

			else{
				$(this)
					.addClass(searchActiveClass)
					.ariaLabel('Close Search Window');

				searchOverlay
					.addClass(searchActiveClass)
					.ariaHidden(false)
					.ariaExpanded(true)
					.fadeIn(timeOut);

				if(!isUsingMobileNav()){
					dropdownParent.each(function (){
						$(this).closeDropdownMenu();
					});
				}
			}
		}

		else{
			$("#main-site-search").submit();
		}
	});

	searchField.on("keyup input", function (){
		var val = $(this).val();

		if(val){
			searchButton
				.removeClass(searchActiveClass)
				.removeAttr('aria-controls')
				.removeAttr('aria-haspopup')
				.removeAttr('aria-expanded')
				.ariaLabel('Search Submit')
				.addClass(searchHasInputClass);
		}

		else{
			searchButton
				.removeClass(searchHasInputClass)
				.addClass(searchActiveClass)
				.ariaExpanded(true)
				.ariaHasPopup(true)
				.ariaControls(searchOverlaySelector)
				.ariaLabel('Close Search Window');
		}
	});

	navToggle.on('click keypress', function (e){
		if($(this).hasClass(activeNavClass)){
			$(this).removeClass(activeNavClass);
			nav
				.removeClass(activeNavClass)
				.ariaHidden(true)
				.ariaExpanded(false);
		}

		else{
			$(this).addClass(activeNavClass);
			nav
				.addClass(activeNavClass)
				.ariaHidden(false)
				.ariaExpanded(true);
		}
	});

	dropdownParent.children("a").click(function (e){
		var parent = $(this).parent();
		var submenu = $(this).siblings('.submenu');
		var dropdowns = dropdownParent.not(parent);

		e.preventDefault();

		if(parent.hasClass(activeNavClass)){
			parent.removeClass(activeNavClass);
			submenu
				.ariaHidden(true)
				.ariaExpanded(false)
				.submenuHide(timeOut);
		}

		else{
			parent.addClass(activeNavClass);
			submenu
				.ariaHidden(false)
				.ariaExpanded(true)
				.submenuShow(timeOut);

			dropdowns.each(function (){
				$(this).closeDropdownMenu(timeOut);
			});
		}
	});

	modalToggle.click(function (e){
		e.preventDefault();

		$($(this).attr('href'))
			.fadeIn()
			.ariaHidden(false)
			.ariaExpanded(true)
			.find('*')
			.filter(':input:visible:first')
			.focus();

		bodyAriaToggle(true);
	});

	$('.modal-close').click(function (){
		$(this)
			.parent()
			.parent()
			.parent()
			.ariaHidden(true)
			.ariaExpanded(false)
			.fadeOut();

		bodyAriaToggle(false);
		modalToggleAria(false);
	});

	$('.modal-wrapper').click(function (e){
		if(e.target === this){
			$(this)
				.fadeOut()
				.ariaHidden(true)
				.ariaExpanded(false);

			bodyAriaToggle(false);
			modalToggleAria(false);
		}
	});

	$('.staff-form.modal-toggle').click(function (e){
		e.preventDefault();
		var option = $(this).attr('data-recipient-id'),
			name = $(this).attr('data-recipient-name');

		$('#staff-form-recipient-name').val(name);
		$('#staff-form-to-email').val(option);
	});

	$('#staff-form-to-email').change(function (){
		var val = $(this).val(),
			optionText = $(this)
				.find('option[value=' + val + ']')
				.text();

		$("#staff-form-recipient-name").val(optionText);
	});

	// # Blog pagination
	if(postsContainer.length >= 0){
		var clicks = 1,
			maxPages = parseInt(postsContainer.attr('data-max-pages'));

		if(clicks === maxPages){
			ajaxLoader.addClass('disabled').addClass('all-loaded');
		}

		ajaxLoader.click(function (e){
			e.preventDefault();
			clicks++;
			loadPosts(clicks);
		});

		var loadPosts = function (pageNumber){
			var url = window.location.href;
			url = url.replace(/\/+$/, "");

			$.ajax({
				url: url + "?page=%",
				type: 'GET',
				data: {page: pageNumber},

				beforeSend: function (){
					ajaxLoader.addClass('disabled').addClass('loading');
				},

				success: function (html){
					var items = $(html).find(postsContainerId).html();
					render(items);

					ajaxLoader.removeClass('loading');

					if(pageNumber < maxPages){
						ajaxLoader.removeClass('disabled');
					}

					else{
						ajaxLoader.addClass('all-loaded');
					}

					ajaxLoader.blur();
				}
			});
			return false;
		};

		var render = function (html){
			var el = $(html);
			postsContainer.append(el);
		};
	}
});